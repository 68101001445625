import { gql } from 'graphql-request';

const trackFacebookCapiMutation = gql`
  mutation ($subdomain: String!, $event: JSON!) {
    capiTrackEvent(subdomain: $subdomain, event: $event) {
      result
      errorMessage
    }
  }
`;

export default trackFacebookCapiMutation;
