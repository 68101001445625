import { gql } from 'graphql-request';

const storeAnalyticsQuery = gql`
  query ($subdomain: String!) {
    store(subdomain: $subdomain) {
      id
      analytics {
        facebookAddTrackId
        googleTagManagerId
        capiEnabled
        snapchatTrackId
        tiktokTrackId
      }
    }
  }
`;

export default storeAnalyticsQuery;
