export const getGtmUserInfo = (userInfo, country) => {
  const { name, phoneNumber, email } = userInfo;
  const enhancedPhoneEg = phoneNumber.replace('+', '');
  const names = name.split(' ');

  const fn = names[0];
  const ln = names.slice(1).join(' ');

  if (email !== undefined && email !== null && email !== '') {
    return {
      name,
      fn,
      ln,
      phoneNumber: enhancedPhoneEg,
      ph: phoneNumber,
      email,
      geo_country: country,
    };
  }
  return {
    name,
    fn,
    ln,
    phoneNumber: enhancedPhoneEg,
    ph: phoneNumber,
    geo_country: country,
  };
};
