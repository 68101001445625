/* eslint-disable */
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import logger from './logger';
// import { fetchServerTime } from './urls/urls';
import ServiceConfigs from 'service/config';

export const logToCloudWatch = logEvents => {
  const logPayload = {
    message: logEvents.message,
    timestamp: new Date().toISOString(),
    level: 'INFO',
  };

  fetch('https://gfcdhpx5y4gkjl7bu4ccc732z40gsvnb.lambda-url.eu-west-1.on.aws/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(logPayload),
  })
    .then(response => {
      if (!response.ok) {
        return response.text().then(text => {
          logger.error({ error: 'Logging to Cloudwatch Error', extraData: text });
        });
      }
      return response.json();
    })
    .catch(err => logger.error({ error: 'Logging to Cloudwatch Error', extraData: err }));
};

const getFacebookSharedEvents = (timestamp: number) => {
  const randomId = Math.round(Math.random() * 10000000000000);
  const subdomain = ServiceConfigs.getSubDomain();

  const generateFbp = () => {
    if (!Cookies.get('_fbp')) {
      return `fb.1.${timestamp}.${randomId}`;
    }
    return Cookies.get('_fbp');
  };

  const generateFbc = () => {
    const existingFbc = Cookies.get('_fbc');
    if (!existingFbc) {
      const userFbclid = Cookies.get(`user_fbclid_${subdomain}`);
      if (userFbclid) {
        return `fb.1.${Date.now()}.${userFbclid}`;
      }
      return null;
    }
    return existingFbc;
  };

  return {
    eventId: uuidv4(),
    eventTime: Math.floor(timestamp / 1000),
    eventSourceUrl: window?.location?.href,
    browserInfo: {
      fbp: generateFbp(),
      fbc: generateFbc(),
      client_user_agent: window?.navigator?.userAgent,
    },
  };
};

// track facebook pixels
const trackFacebookPixels = (eventName, eventValues, facebookSharedEvents) => {
  window.fbq(
    'track',
    eventName,
    {
      ...eventValues,
    },
    { eventID: facebookSharedEvents.eventId },
  );
};

// track facebook capi with backend

const trackFacebookCapi = async (eventName, eventValues, facebookShared, userData, capiMutation) => {
  let transformedObject = {};

  if (eventValues.contents) {
    transformedObject = {
      ...eventValues,
      contents: eventValues.contents.map(({ id, ...rest }) => ({
        ...rest,
        product_id: id,
      })),
    };
  } else {
    transformedObject = eventValues;
  }

  let ipAddress = null;
  try {
    const ipResponse = await fetch('https://api.ipify.org/?format=json');
    const data = await ipResponse.json();
    ipAddress = data?.ip;
  } catch (error) {
    logger.error({ error: 'IpAddress CAPI Error, 🚀 ~', extraData: error });
  }

  const capiPayload = {
    event_id: facebookShared.eventId,
    event_time: facebookShared.eventTime,
    event_source_url: facebookShared.eventSourceUrl,
    event_name: eventName,
    browserInfo: {
      ...facebookShared.browserInfo,
      ...(ipAddress && { client_ip_address: ipAddress }),
    },
    ...userData,
    ...transformedObject,
  };

  capiMutation({ ...capiPayload });
  return capiPayload;
};

// init facebook tracking event
export const trackFacebookEvent = async (eventName, eventValues, userData, capiMutation, capiEnabled) => {
  // const serverTimestamp = await fetchServerTime();
  const serverTimestamp = Date.now();
  const facebookSharedEvents = getFacebookSharedEvents(serverTimestamp);

  try {
    if (window && window.fbq) {
      trackFacebookPixels(eventName, eventValues, facebookSharedEvents);
    }
  } catch (error) {
    logger.error({ error: 'Facebook Pixel Error, 🚀 ~ trackFacebookPixels', extraData: error });
  }

  if (capiEnabled) {
    try {
      await trackFacebookCapi(eventName, eventValues, facebookSharedEvents, userData, capiMutation);
    } catch (error) {
      logger.error({ error: 'Facebook CAPI Error, 🚀 ~ trackFacebookCapi', extraData: error });
    }
  }
};
