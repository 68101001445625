import { storeAnalyticsQuery } from '../../queries';
import { QueryHookResponse } from '../../types/internal';
import ServiceConfigs from '../../config';
import { Store } from '../../types/generated';
import { useQuery } from '../../utils';

type UseStoreAnalyticsType = ({ initialData }?: { initialData?: Store }) => QueryHookResponse<Store>;

const useStoreAnalytics: UseStoreAnalyticsType = ({ initialData } = {}) =>
  useQuery<Store>({
    query: storeAnalyticsQuery,
    variables: { subdomain: ServiceConfigs.getSubDomain() },
    handler: (res: any) => res.store,
    config: { initialData },
  });

export default useStoreAnalytics;
