import { apiMutate } from 'service/utils/mutation';
import ServiceConfigs from '../../config';
import trackFacebookCapiMutation from '../../mutations/analytics/trackFacebookCapi';
import { MutationCapiTrackEventArgs } from '../../types';

type useCreateFacebookCapiType = () => (variables: Partial<MutationCapiTrackEventArgs>) => Promise<void>;

const useCreateFacebookCapi: useCreateFacebookCapiType = () => variables =>
  apiMutate({
    mutation: trackFacebookCapiMutation,
    variables: { subdomain: ServiceConfigs.getSubDomain(), event: { ...variables } },
    handler: (res: any) => res,
  });

export default useCreateFacebookCapi;
