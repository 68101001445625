import { gql } from 'graphql-request';

const userInfoQuery = gql`
  query ($subdomain: String!) {
    storeFrontUser {
      id
      name
      phoneNumber
      email
      walletBalance(subdomain: $subdomain)
    }
  }
`;

export default userInfoQuery;
