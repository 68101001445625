import { DataLayerConfigs } from 'common/lib/tracking';
import { DATA_LAYER_VARIABLES } from 'common/constants';

import { getGtmUserInfo } from 'common/utils';
import { usePersistedCountryDetails } from 'common/hooks';
import { StoreFrontUser } from '../../types/generated';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import { userInfoQuery } from '../../queries';
import ServiceConfigs from '../../config';

type useUserInfoType = ({
  updateCashBalance,
  initialData,
  onComplete,
}?: {
  updateCashBalance?: boolean;

  initialData?: StoreFrontUser;
  onComplete?: (res: any) => void;
}) => QueryHookResponse<StoreFrontUser>;

const useUserInfo: useUserInfoType = ({ updateCashBalance, initialData, onComplete } = {}) => {
  const authHeaders = ServiceConfigs.getAuthorizationHeader();
  const headers = authHeaders ? { ...authHeaders } : null;
  const subdomain = ServiceConfigs.getSubDomain();
  const [persistedCountryDetails] = usePersistedCountryDetails();

  return useQuery<StoreFrontUser>({
    query: userInfoQuery,
    variables: headers && subdomain ? { subdomain } : null,
    requestHeaders: headers || null,
    handler: (res: any) => {
      DataLayerConfigs.pushToDataLayer({
        [DATA_LAYER_VARIABLES.STORE_FRONT_USER]: getGtmUserInfo(
          res?.storeFrontUser,
          persistedCountryDetails?.code.toLowerCase(),
        ),
      });
      if (onComplete) onComplete(res);
      return res?.storeFrontUser;
    },
    config: { revalidateOnMount: updateCashBalance, initialData },
  });
};

export default useUserInfo;
