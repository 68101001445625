import { camelCase } from 'camel-case';

/**
 * Returns the same given value but with all the keys turned into camel case.
 *
 * @param value
 */
export const transformToCamelCaseKeys: (value: any) => any = value => {
  const valueClone: any = {};
  if (typeof value !== 'object' || !value) return value;
  Object.keys(value).forEach(key => {
    valueClone[camelCase(key)] = transformToCamelCaseKeys(value[key]);
  });
  return valueClone;
};

export const capitalizeTxt = txt => txt.charAt(0).toUpperCase() + txt.slice(1);
